<template>
  <div class="row cardArea">
    <!-- <div class="innerBox col-md-12" :class="locale === 'en' ? 'col-xl-3' : 'col-xl-2'" v-for="(cardOption, index) in options" :key="index"> -->
    <div class="innerBox col-md-12" :class="'col-xl-2'" v-for="(cardOption, index) in changedCardOptions" :key="index">
      <!-- cardBox의 값들중 base값과 compare값 둘다 있어야 보여주도록 코드작성 -->
      <card-box ref="cardBox" :options="cardOption" v-if="cardOption.compVal||cardOption.baseVal"/>
    </div>
  </div>

</template>

<script>

// Default CSS
import "element-ui/lib/theme-chalk/index.css";
import CardBox from "@views/component/cardBox/analysis/BasicCard-v2.vue";

export default {
  components: {
    CardBox,
  },
  props: ["options"],
  data() {
    return {
      changedCardOptions:null
    } 
  },
  computed:{
    locale(){
      return this.$store.state.locale;
    }
  },
  watch: {
    options(){
      this.editOptions(this.options);
    }
   },
  created() { 
   
     this.editOptions(this.options);
  },
  methods:{
     //카드 컴포넌트에서 단위가 원단위일 시 정수부분만 보여주도록 동작하는 함수
    editOptions(optionArr){
       this.changedCardOptions=optionArr.map(item => {
        if(item.unit==="원"){
        return {...item,
          baseVal:item.baseVal.toFixed(0),
          compVal:item.compVal?item.compVal.toFixed(0):item.compVal
        }
        }else{
          return item;
  }
      });
      console.log('this.changedCardOptions',this.changedCardOptions)
    }
  },
  mounted() { 
    console.log('options in LightAnalysisCards',this.options)
  },
}

</script>

<style scoped>

</style>