<!-- Component Data Model
CompareCard options: {
  color: '',
  unit: '',
  icon: '',
  compType: '',

  title: '',
  baseVal: 0,
  compVal: 0,
  diffRate: 0,
}
 -->
<template>
  <div class="xeDataCard heightMAX">
    <div class="kbet-custom-reset heightMAX">
      <div
        class="rounded kbet-widget-valueCard-v1-anal heightMAX compareCardStyligV1Anal" :style="{ 'background-color': options.color }">
        <i class="material-icons bg-icon">{{ options.icon }}</i>
        <div class="d-flex">
          <p class="title">{{ $t(options.title) }}</p>
          <div class="value-usage ml-auto">
            <strong>{{ Number(options.baseVal).toFixed(1) | numberWithCommas }}<span>{{ options.unit }}</span></strong>
          </div>
        </div>
       
        <div class="value-bottom d-flex align-items-center"  :style="{ 'background-color': '#00000022' }" >
          <span v-if="options.compType == 'previousDay'">{{ $t("전일") }} : {{ options.compVal | numberWithCommas }} {{ options.unit }}</span>
          <span v-else-if="options.compType == 'previousWeek'">{{ $t("전주") }} : {{ options.compVal | numberWithCommas }} {{ options.unit }}</span>
          <span v-else-if="options.compType == 'previousMonth'">{{ $t("전월") }} : {{ options.compVal | numberWithCommas }} {{ options.unit }}</span>
          <span v-else-if="options.compType == 'previousYear'">{{ $t("전년") }} : {{ options.compVal | numberWithCommas }} {{ options.unit }}</span>
          <span v-else></span>
            <!-- <span>{{ options.compVal | numberWithCommas }} <span>{{ options.unit }}</span></span> -->
          <div class="ml-auto value-contrast" v-if="options.compType!=='none'">
            <dd v-if="options.diffRate > 0" class="contrast-up align-text-bottom">
              <strong>+{{ options.diffRate | numberWithCommas }}%</strong>
            </dd>
            <dd v-else-if="options.diffRate < 0" class="contrast-down align-text-bottom">
              <strong>{{ options.diffRate | numberWithCommas }}%</strong>
            </dd>
            <dd v-else-if="options.diffRate == '-'" class="contrast-same2 align-text-bottom">
              <strong>{{$t('변동없음')}}</strong>
            </dd>
            <dd v-else-if="options.diffRate == 0" class="contrast-same align-text-bottom">
              <strong>{{$t('변동없음')}}</strong>
            </dd>
             <dd v-else-if="options.diffRate ==='infinityVal'" class="contrast-same2 align-text-bottom">
              <strong class="infinityVal">-</strong>
            </dd>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<style></style>

<script>

export default {
  name: "xeDataCard",
  props: [
    "options",
    // "color",
    // "unit",
    // "icon",
    // "compType",
    // "cardData",
  ],
  components: {},
  data() {
    return {
      point: [],
      data: [],
    };
  },
  computed: {
  },
  created() {
   
  },
  mounted() {
    // let dataCard = document.getElementsByClassName("xeDataCard");

    // for (let i = 0; i < dataCard.length; i++) {
    //   dataCard[i].parentNode.style.height = "100%";
    // }
  },
  watch: {
  },
  methods: {
  }
};
</script>
